import React, { useEffect, useState } from 'react';
import Image from 'next/future/image'
import { motion, useAnimation } from 'framer-motion';

const HomeFeatures = ({features}) => {

    const [activeTab, setActiveTab] = useState(0)
    const [pulsing, setPulsing] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);

    const imageLoaded = () => {
      setImageLoading(false);
      setTimeout(() => setPulsing(false), 100);
    };

    const canDoTab = () => (
        <div className="cando-tab-container p-3 d-flex flex-grow-1 flex-column ms-4">
            <div className="cando-tab-desc p-3 d-flex mx-auto">{features[activeTab].description}</div>
            <div className="d-flex position-relative">
                <Image
                    src={features[activeTab].image}
                    className="cando-tab-img w-lg-75 w-100 h-auto"
                    width={319}
                    height={232}
                    alt={features[activeTab].name}
                />
            </div>
        </div>
    )

    const canDoTitle = (canDo, index) => (
        <div key={index} className="d-flex can-do-class flex-grow-1 flex-column me-4">
            <div
                className={`${activeTab === index ? 'active  ' : ''}cando-tab-title  d-flex flex-row m-2 m-lg-3 flex-grow-1`}
                key={index}
                onClick={() => {
                    setActiveTab(index)
                    setImageLoading(true)
                    setPulsing(true)
                }}
            >
                <div className='left-icon p-3 p-lg-4'>
                    <div className="cando-icon-container position-relative"><img src={canDo.icon} alt="feature icons" /></div>
                </div>
                <h3 className='ms-3 d-flex justify-content-center condo-data flex-column'>
                    {canDo.name}
                    <span className='cando-desc'>
                        {canDo.headline}
                    </span>
                </h3>
            </div>

            {activeTab === index && <motion.div className={`d-lg-none my-3`} initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}>{canDoTab()}</motion.div>}
        </div>
    )

    return (
        <>
            <div className="cando-container py-md-3 px-md-5 py-5 px-2 row g-0">
                <div className="col-xl-10 offset-xl-1 col-12">
                    <div className="row g-0">
                        <div className="col pt-md-5 mb-4">
                            <div className="cando-title text-center">
                                One<span className="theme-text"> Platform</span>, Many <span className="theme-text">Solutions</span>

                            </div>
                            <div className='sub-text text-center mx-auto'>
                                KonfHub offers an all-in-one platform for event management to ensure your event or conference runs smoothly and professionally
                            </div>
                        </div>
                    </div>
                    <div className="row g-0 mt-3">
                        <div className="col-lg-5 col-12 mb-4 d-flex flex-grow-1  flex-column">
                            {
                                features.map(canDoTitle)
                            }
                        </div>
                        <div className="col-lg-7 col-0 mb-lg-4 d-none d-lg-block  px-3">
                            {
                                features.map((canDo, index) => (
                                    <>
                                        {index === activeTab && (
                                        <motion.div className={`cando-tab-container align-items-center p-3 d-flex flex-grow-1 flex-column ms-4`}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 1 }}
                                        >
                                            <div className="cando-tab-desc p-3 d-flex mx-auto">{canDo.description}</div>
                                            <div className={`${pulsing ? "pulse" : ""} loadable d-flex position-relative`} style={{ maxWidth: "650px" , width: "100%", background: imageLoading ? "#e6e6e6" : "white"}} >
                                                <motion.img
                                                    src={canDo.image}
                                                    initial={{opacity: 0, y: 50 }}
                                                    animate={{
                                                        opacity: imageLoading ? 0 : 1,
                                                        y: 0
                                                      }}
                                                    transition={
                                                      { opacity: { duration: 1 },
                                                        y: { duration: 1 } }
                                                    }
                                                    className="cando-tab-img w-lg-75 w-xxl-50 w-100 h-auto"
                                                    onLoad={imageLoaded}
                                                    alt={canDo.name}
                                                />
                                            </div>
                                        </motion.div>
                                        )}
                                    </>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <style>{`
                    .cando-container {
                        background: #ffffff;
                        font-family: 'Nunito';
                    }

                    @keyframes fadeIn {
                        from {
                          opacity: 0;
                        }
                        to {
                          opacity: 1;
                        }
                    }

                    .animate-fade {
                      animation: fadeIn 1s ease-in;
                    }

                    .pulse {
                      animation: pulse 1.4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
                    }

                    @keyframes pulse {
                        0%,
                        100% {
                          opacity: 1;
                        }
                        50% {
                          opacity: 0.3;
                        }
                      }

                    .loadable {
                        border-radius: 6px;
                        overflow: hidden;
                        margin: 0 auto;
                    }

                    .left-icon{
                        background: #572148 !important;
                        border-radius: 8px 0px 0px 8px;
                    }
                    .sub-text{
                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        text-align: center;
                        width: 90%;
                        color: #4F4F4F;
                    }

                    .cando-container .cando-title {
                        color: #572148;
                        font-weight: 800;
                        font-size: 50px;
                        font-family: "Nunito";
                    }

                    .cando-container .cando-title .theme-text {
                        color: #FB5850;
                    }

                    .cando-desc{
                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 25px;
                        /* identical to box height */
                        color: rgba(87, 33, 72, 0.73);
                    }

                    .cando-container .cando-tab-title {
                        background: #FFFFFF;
                        border-radius: 8px;
                        color: #572148;
                        cursor: pointer;
                        border-radius: 8px;
                        font-weight: 700;
                        max-width: 450px;
                        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
                        font-size: 18px;
                        line-height: 34px;
                        align-items: center;
                        transition: 0.2s all;
                    }

                    .cando-container .cando-tab-title.active {
                        border-radius: 8px;
                        transform: scale(1.1);
                        box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.25);
                        border-radius: 8px;
                    }
                    .cando-container .cando-tab-title.active:hover {
                        transform: scale(1.1);
                    }
                    .cando-container .cando-tab-title:hover {
                        transform: scale(1.02);
                    }

                    .cando-container .cando-tab-title .cando-icon-container {
                        display: inline-flex;
                        background: #572148;
                        color: #FFF;
                        width: 40px;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                    }


                    .cando-container .cando-tab-container .cando-tab-desc {
                        font-family: Nunito;
                        font-size: 26px;
                        font-weight: 700;
                        line-height: 36px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #572148;
                    }
                    .cando-container .cando-tab-container {
                        min-height: 500px;
                        max-height: 500px;
                    }


                    .can-do-class *{
                        max-height: 98px;
                    }
                    /* SM screens */
                    @media only screen and (min-width: 480px) and (max-width: 768px) {
                        .cando-container .cando-title {
                            font-size: 35px;
                        }

                        .cando-container .cando-tab-title {
                            font-size: 16px;
                        }
                    }

                    @media only screen and (max-width: 992px) {
                        .cando-container .cando-tab-container .cando-tab-desc {
                            font-size: 16px;
                        }
                        .cando-container .cando-tab-container {
                            min-height: 320px;
                            margin-left: 0px !important;
                            max-height: 500px;
                    }
                        .condo-data{
                            font-size: 14px;
                        }
                        .can-do-class * { max-height: 1098px;}
                        .cando-desc{
                            font-size: 12px;
                            line-height: 18px;
                        }
                        .cando-container .cando-tab-title {
                        max-height: 75px;
                        width: 330px;
                    }
                    .left-icon{
                        max-height: 75px;
                    }
                        .can-do-class{
                            display: flex;
                            margin-right: 0px !important;
                            justify-content: center;
                            align-items: center;
                        }

                    }

                    /* XS screens */
                    @media only screen and (max-width: 480px) {
                        .cando-container .cando-title {
                            font-size: 35px;
                        }
                        .cando-container .cando-tab-title.active {
                            transform: scale(1.07) !important;
                        }
                        .cando-container .cando-tab-title {
                            font-size: 16px;
                        }
                    }
                `}</style>
        </>
    )
}

export default HomeFeatures;
